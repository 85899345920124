import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/Hero'
import PatientBehaviorCard from '../components/PatientBehaviorCard'
import QuoteBox from '../components/QuoteBox'
import CallToAction from '../components/CallToAction'
import iconBottleSmile from '../images/icon-bottle-smile.svg'
import iconGlobe from '../images/icon-globe.svg'
import iconData from '../images/icon-data.svg'

const researchPage = () => {
  return (
    <main className="researchPage">
      <Hero
        color="blue"
        image="data-devices"
        heading="Comprehensive data, collected with ease"
        text="Optimized protocol adherence tracking with easy-to-use technology and reliable dose-level data for researchers."
      />
      <div className="page-section">
        <div className="page-section-content">
          <h2>A smart way to track and collect dosing data</h2>
        </div>
        <div className="page-section-content detailed-adherence-data">
          <p>
            When conducting research on the benefits of a particular therapy, having the confidence that patients have
            actually taken the medication as per the protocol is critical. Aidia gives you the confidence, that not only
            have patients taken their medication but also provides insightful data on exactly when patients took the
            medication, down to the second.
          </p>
        </div>
      </div>

      <div className="page-section">
        <div className="page-section-content patient-behavior-cards">
          <PatientBehaviorCard
            image={iconBottleSmile}
            altText="icon bottle smile"
            content="Easy-to-use with flexible settings to match your study needs. Patients are more likely to stay adherent in the study protocol, and have been consistently shown to experience high satisfaction rates with Aidia."
          />
          <PatientBehaviorCard
            image={iconData}
            altText="icon data"
            content="Real-time medication-taking data that flows into your management systems with customizable reports to support interim and adjunct analyses."
          />
          <PatientBehaviorCard
            image={iconGlobe}
            altText="icon globe"
            content="Proven protocol-monitoring experience with over 50 of the leading medical centers around the world."
          />
        </div>
      </div>

      <div className="page-section">
        <div className="page-section-content research-quote">
          <QuoteBox
            color="blue"
            text="My research colleagues and I conducted a randomized controlled trial of an educational intervention to improve glaucoma medication adherence. The patients using the Aidia Smart Bottle and messaging functions showed 20% higher adherence to the medication regimen compared to the control arm."
            speaker="Kelly M., MD, from a regional health center"
          />
        </div>
        <div className="page-section-content research-quote">
          <QuoteBox
            color="blue"
            text="Given the adherence seen in the trial, my sense is that the AdhereTech smart bottle has a very positive impact on our study’s adherence rates."
            speaker="Calman Prussin., MD, from Areteia Therapeutics"
          />
        </div>
      </div>

      <div className="page-section--blue aidia-smart-cap">
        <div className="page-section-content">
          <div className="aidia-smart-cap-img">
            <StaticImage
              src="../images/aidia-smart-cap.png"
              alt="The Aidia Smart Cap"
              placeholder="none"
              layout="constrained"
            />
          </div>
          <div className="aidia-smart-cap-img-mobile">
            <StaticImage
              src="../images/aidia-smart-cap.png"
              alt="The Aidia Smart Cap"
              height={200}
              placeholder="none"
              layout="constrained"
            />
          </div>
          <div className="aidia-smart-cap-text">
            <h2>The Aidia Smart Cap</h2>
            <h2 className="sub-heading">
              Our innovative Aidia device has all the features of our Smart Bottle technology, without the need to
              transfer investigation product between containers. It does this by fitting over standard prescription
              bottle caps to ensure medication stability.
            </h2>
            <ul>
              <li>Zero patient setup – always connected via cellular</li>
              <li>Works worldwide, even in areas with low cellular coverage</li>
              <li>Cap fits multiple size Rx bottle caps</li>
              <li>No contact with medication</li>
              <li>Smart prompts, OLED message screen, lights and chimes</li>
              <li>Dose behavior recorded in real-time, with or without patient prompting/reminder</li>
              <li>5 months of battery life; charges via standard USB-C</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="page-section health-equity">
        <div className="page-section-content">
          <h2>Health equity issues affect protocol compliance</h2>
        </div>
        <div className="page-section-content">
          <p>
            Increasing study population diversity increases risk of higher protocol non-compliance due to longstanding
            health equity issues.
            <br />
            <br />
            Across disease states, patients struggle with persistence on medication. This is even lower in minority
            populations. The odds of non-adherence to antihypertensive medications were 47% & 44% higher among Black &
            Hispanic patients, as compared to white patients.
            <br />
            <br />
            Lower medication adherence among Black and Brown communities is not limited to one or a few health
            conditions — these disparities have been demonstrated in communities of color across a wide array of
            diseases.
            <br />
            <br />
          </p>
          <div className="sources">
            <div>Sources:</div>
            <div>
              <a
                href="https://phrma.org/en/Equity/Addressing-Disparities-in-Medication-Access-and-Adherence"
                target="_blank"
                rel="noopener noreferrer"
              >
                PhRMA: Addressing Disparities in Medication Access and Adherence, May 2022
              </a>
              <br />
              <a
                href="https://phrma.org/resource-center/topics/equity/health-inequities-impede-access-to-medicines"
                target="_blank"
                rel="noopener noreferrer"
              >
                PhRMA: REPORT Health Inequities Impede Access to Medicines, May 2023
              </a>
            </div>
          </div>
          <div className="page-section-content">
            <StaticImage
              src="../images/demographics-table.png"
              alt="Demographics Table"
              placeholder="none"
              layout="constrained"
              width={900}
            />
          </div>
        </div>
      </div>

      <div className="page-section--blue protocol-compliance">
        <div className="page-section-content">
          <h2>Aidia shows positive impact on protocol compliance</h2>
        </div>
        <div className="page-section-content">
          <p className="final-p">
            In a 12-week Ph2 Asthma study, over 90% of patients remained adherent throughout.
            <br />
            <br />
            <em>
              Source:{' '}
              <a href="https://pubmed.ncbi.nlm.nih.gov/37277072/" target="_blank" rel="noopener noreferrer">
                Safety and Efficacy of Dexpramipexole in Eosinophilic Asthma (EXHALE): A randomized controlled trial
              </a>
            </em>
          </p>
        </div>
        <div className="page-section-content">
          <StaticImage
            src="../images/protocol-compliance-chart.png"
            alt="Percentage of Aidia Patients with >90% Adherence"
            placeholder="none"
            layout="constrained"
            width={900}
          />
        </div>
      </div>

      <div className="page-section research-partners">
        <div className="page-section-content">
          <h2>AdhereTech has partnered with leading research partners</h2>
        </div>
        <div className="page-section-content">
          <StaticImage
            src="../images/research-partner-logos.png"
            alt="Research Partners"
            placeholder="none"
            layout="constrained"
            width={900}
          />
        </div>
      </div>

      <div className="page-section notable-published-studies">
        <div className="page-section-content">
          <h2>Notable published studies</h2>
        </div>
        <div className="page-section-content">
          <p>
            <a href="https://pubmed.ncbi.nlm.nih.gov/37277072/" target="_blank" rel="noopener noreferrer">
              Safety and Efficacy of Dexpramipexole in Eosinophilic Asthma (EXHALE): A randomized controlled trial
            </a>
            <br />
            June 2023
            <br />
            <br />
            <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7735215/" target="_blank" rel="noopener noreferrer">
              Randomized Pilot Study of an Advanced Smart Pill Bottle as an Adherence Intervention in Patients with HIV
              on Antiretroviral Treatment
            </a>
            <br />
            January 2021
            <br />
            <br />
            <a
              href="https://www.sciencedirect.com/science/article/abs/pii/S2589419620301083?via%3dihub"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Impact of the Support, Educate, Empower Personalized Glaucoma Coaching Pilot Study on Glaucoma
              Medication Adherence
            </a>
            <br />
            February 2020
            <br />
            <br />
            <a href="https://www.jmcp.org/doi/10.18553/jmcp.2019.25.11.1244" target="_blank" rel="noopener noreferrer">
              Effect of a Smart Pill Bottle and Pharmacist Intervention on Medication Adherence in Patients with
              Multiple Myeloma New to Lenalidomide Therapy
            </a>
            <br />
            October 2019
          </p>
        </div>
      </div>

      <div className="page-section">
        <div className="page-section-content">
          <CallToAction
            headerText="Request a quote"
            content="Let our representative know what you need for your research study."
            linkText="Contact Us"
            linkUrl="/contact-us?organizationType=researcher"
            color="blue"
          />
        </div>
      </div>
    </main>
  )
}

export default researchPage
